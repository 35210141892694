import 'slick-carousel'
import AOS from 'aos';
jQuery( document ).ready(function($) {

  // Mobile Menu
  $('.menu-trigger').click(function(e){
    e.preventDefault()
    $(this).toggleClass('active')
    $('.menu-menu-container, .extra').slideToggle()
  })
  
  $('.menu-trigger-extra').click(function(e){
    e.preventDefault()
    $(this).toggleClass('active')
    $('.menu-extra-container').slideToggle()
  })

  // Hero Slider
  $('.slider-hero').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
  });

  // Animations
  AOS.init({
    duration: 1000,
    once: true,
  });

  // Services Slider
  $('.slider-services').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    arrows: false,
    dots: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  });

  // accordion
  $('.accordion .trigger').click(function(){
    $(this).find('.arrow').toggleClass('active')
    $(this).next('.content').slideToggle()
  })

  $('.close-accordion').click(function(e){
    e.preventDefault()
    $('.arrow').removeClass('active')
    $('.content').hide()
  })

  $('header .test, .testing-services .service').click(function(e){
    e.preventDefault()
    const go2ID = $(this).find('a').text().replace(/\s+/g, '-').toLowerCase()
    window.location.href = `././testing-portfolio/?goto=${go2ID}`
  })

  $('.testing-services .service').click(function(e){
    e.preventDefault()
    const go2ID = $(this).attr('href').replace('#', '')
    window.location.href = `././testing-portfolio/?goto=${go2ID}`
  })
  
  $.urlParam = function(name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href)
    return results[1] || 0
  }
  
  if (window.location.href.indexOf("testing-portfolio") > -1) {
    const targetTest = $.urlParam('goto')
    $('html, body').animate({scrollTop: $(`#${targetTest}`).offset().top -224 }, 'slow')
    $(`#${targetTest}`).find('.content').slideToggle()
    $(`#${targetTest}`).find('.arrow').toggleClass('active')
  }

});